/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { BACK_ICON } from '#/constants/common/images.constants';
import { useSearchParams } from 'react-router-dom';
import {
  ContentContainer,
  ContentText,
  TabTitleText,
  FieldContainer,
  InputLabel,
  InputField,
  InputFieldWrapper,
  SgeText,
} from '../ResponsibleGambling.styled';
import SaveButton from './SaveButton.component';
import { useSelector } from 'react-redux';
import { BetLimitState } from '#/utils/slices/responsibleGamblingSlice';
import { RootState } from '#/utils/store';
import CentralSpinLoader from '#/components/common/CentralSpinLoader';
import { useEffect, useState } from 'react';
import { useQuerySmartContract } from '#/hooks/useQuerySmartContract';
import useHashedUserId from '#/hooks/useHashedUserId';
import useBackendUserData from '#/utils/dataLoaders/useBackendUserData';
import ImgActionTag from '#/components/common/ImageActionTag.component';

interface ResponsibleGamblingLimits {
  gambling_limits: {
    daily: string;
    weekly: string;
    monthly: string;
  };
  currently_allowed_limits: {
    daily: string;
    weekly: string;
    monthly: string;
  };
}

export default function BetLimit() {
  const { betLimit } = useSelector(
    (state: RootState) => state.responsibleGambling,
  );
  const [limits, setLimits] = useState<BetLimitState>(betLimit);

  const { data : hashedUserId } = useHashedUserId()
  const { data : user } = useBackendUserData()

  const queryMsg = { account: { user_id: hashedUserId } };

  const { data, isLoading: loading } =
    useQuerySmartContract<ResponsibleGamblingLimits>(queryMsg, !!hashedUserId);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const numericValue = Number(value);
    setLimits((prevLimits) => ({
      ...prevLimits,
      [name]: numericValue,
    }));
  };

  const [, setSearchParams] = useSearchParams();

  const handleBackClick = () => {
    setSearchParams({});
  };
  // Update the local state with the fetched gambling limits
  useEffect(() => {
    if (data?.gambling_limits && !loading) {
      setLimits({
        dailyLimit: Number(data.gambling_limits.daily),
        weeklyLimit: Number(data.gambling_limits.weekly),
        monthlyLimit: Number(data.gambling_limits.monthly),
      });
    }
  }, [data, loading]);

  return (
    <div className={ContentContainer}>
      <h3 className={`${TabTitleText} mb-4`}>
        <ImgActionTag
          className="w-2.5 mr-2 cursor-pointer"
          src={BACK_ICON}
          alt="Back Icon"
          onClick={handleBackClick}
          dataId="navigate_back"
        />
        BET LIMIT
      </h3>
      <p className={ContentText}>
        {`This facility enables you to limit the amount of money that you are able to deposit online into your account on either a 24-hour, 7-day, and/or 30-day basis. It's an effective way to control your spending and ensure responsible gambling practices. At Six Sigma, we prioritize your well-being and promote responsible gambling, and our Bet Limits feature is designed to support you in making informed decisions about your betting activities.`}
      </p>
      <div className="mt-5 relative">
        <div className={FieldContainer}>
          <label htmlFor="daily_limit" className={InputLabel}>
            Daily Limit
          </label>
          <div className={InputFieldWrapper}>
            <input
              type="text"
              name="dailyLimit"
              id="daily_limit"
              className={InputField}
              placeholder="0"
              value={limits.dailyLimit}
              onChange={handleChange}
            />
            <span className={SgeText}>SGE</span>
          </div>
        </div>
        <div className={FieldContainer}>
          <label htmlFor="weekly_limit" className={InputLabel}>
            Weekly Limit
          </label>
          <div className={InputFieldWrapper}>
            <input
              type="text"
              name="weeklyLimit"
              id="weekly_limit"
              className={InputField}
              placeholder="0"
              value={limits.weeklyLimit}
              onChange={handleChange}
            />
            <span className={SgeText}>SGE</span>
          </div>
        </div>
        <div className={FieldContainer}>
          <label htmlFor="monthly_limit" className={InputLabel}>
            Monthly Limit
          </label>
          <div className={InputFieldWrapper}>
            <input
              type="text"
              name="monthlyLimit"
              id="monthly_limit"
              className={InputField}
              placeholder="0"
              value={limits.monthlyLimit}
              onChange={handleChange}
            />
            <span className={SgeText}>SGE</span>
          </div>
        </div>
        <SaveButton betLimits={limits} />
        {loading && !user ? <CentralSpinLoader /> : null}
      </div>
    </div>
  );
}
