import {
  COPY_ICON,
  SUCCESS_ICON_ACTIVE,
} from '#/constants/common/images.constants';
import {
  CardContainer,
  CardHeader,
  DateText,
  CardFooter,
  FlexCenter,
  TxAddress,
  LabelText,
  ValueText,
  CopyIcon,
  SuccessBadge,
  FailedBadge,
  TxAddressWrapper,
  TableColGap,
} from './Transactions.styled';
import Button from '#/components/common/Buttons/Button/Button.component';
import RedirectLink from '../common/RedirectLink.component';
import { Transaction } from '#/utils/backend';
import env from '#/utils/env';
import { copyToClipboard } from '#/utils/string/string';
import { CustomToast } from '../common/ToastNotification/ToastConfig';
import { toast } from 'react-toastify';
import { formatDateToDesiredFormat } from '#/utils/datetime/datetime';
import { DATE_TIME_FORMATS } from '#/utils/datetime/datetime.constants';

interface TransactionCardProps {
  transaction: Transaction;
}
interface TransactionTableProps {
  transaction: Transaction;
}

export default function TransactionCard({ transaction }: TransactionCardProps) {
  const getBadgeClass = (status: string) => {
    switch (status) {
      case 'Success':
        return SuccessBadge;
      case 'Failed':
        return FailedBadge;
      default:
        return '';
    }
  };

  async function copyAddress(address: string) {
    await copyToClipboard(address);
    toast.error(
      <CustomToast
        title={'Copied successfully'}
        subText="Transaction hash copied to your clipboard."
        iconSrc={SUCCESS_ICON_ACTIVE}
        iconClass="w-6"
      />,
      { icon: false },
    );
  }

  return (
    <div className={CardContainer} key={transaction.hash}>
      <div className={CardHeader}>
        {/* <span className={SuccessBadge}>{transaction.status}</span> */}
        <span className={getBadgeClass(transaction.status)}>
          {transaction.status}
        </span>
        <span className={DateText}>
          {formatDateToDesiredFormat(
            transaction.createdAt,
            DATE_TIME_FORMATS.DATE_TIME_6,
          )}
        </span>
      </div>
      <div className={FlexCenter}>
        <RedirectLink
          className={TxAddress}
          href={`${env.blockExplorer}/sge/tx/${transaction.hash}`} // Replace with actual explorer link
          target="_blank"
          rel="noreferrer"
          dataId="sportsbook_transaction_explorer_link"
        >
          <span
            className={TxAddress}
          >{`${transaction.hash.slice(0, 24)}....${transaction.hash.slice(
            transaction.hash.length - 5,
            transaction.hash.length,
          )}`}</span>
        </RedirectLink>
        <Button onClick={() => copyAddress(transaction.hash)} dataId="copy_transaction_hash">
          <img className={CopyIcon} src={COPY_ICON} alt="Copy Icon" />
        </Button>
      </div>
      <div className={CardFooter}>
        <span className="flex-1">
          <span className={LabelText}>Type:&nbsp;</span>
          <span className={ValueText}>{transaction.type}</span>
        </span>
        <span className="flex-1">
          <span className={LabelText}>Height:&nbsp;</span>
          <span className={ValueText}>{transaction.height}</span>
        </span>
      </div>
    </div>
  );
}


export function TransactionTable({ transaction }: TransactionTableProps) {
  const getBadgeClass = (status: string) => {
    switch (status) {
      case 'Success':
        return SuccessBadge;
      case 'Failed':
        return FailedBadge;
      default:
        return '';
    }
  };

  async function copyAddress(address: string) {
    await copyToClipboard(address)
    toast.error((<CustomToast
      title={"Copied successfully"}
      subText="Transaction hash copied to your clipboard."
      iconSrc={SUCCESS_ICON_ACTIVE}
      iconClass="w-6"
    />), { icon: false });
  }

  return (
    <tr key={transaction.hash}>
      <td className={TableColGap}>
        <div className={TxAddressWrapper}>
          <RedirectLink
            className={TxAddress}
            href={`${env.blockExplorer}/sge/tx/${transaction.hash}`} // Replace with actual explorer link
            target="_blank"
            rel="noreferrer"
            dataId="sportsbook_transaction_explorer_link"
          >
            <span className={TxAddress}>{`${transaction.hash.slice(0, 24)}....${transaction.hash.slice(transaction.hash.length - 5, transaction.hash.length)}`}</span>
          </RedirectLink>
          <Button onClick={() => copyAddress(transaction.hash)}>
            <img className={CopyIcon} src={COPY_ICON} alt="Copy Icon" />
          </Button>
        </div>
      </td>
      <td className={TableColGap}><span className={ValueText}>{transaction.type}</span></td>
      <td className={TableColGap}><span className={ValueText}>{transaction.height}</span></td>
      <td className={TableColGap}><span className={getBadgeClass(transaction.status)}>
        {transaction.status}
      </span></td>
      <td className={TableColGap}>
        <span className={DateText}>{formatDateToDesiredFormat(transaction.createdAt, DATE_TIME_FORMATS.DATE_TIME_6)}</span>
      </td>
    </tr>
  );
}