import React from 'react';
import { useSelector } from 'react-redux';
import useInfiniteBetsDataLoader from '#/utils/dataLoaders/useInfiniteBetsDataLoader';
import LoadingComponent from './BetActivityLoading.component';
import EmptyList from '../common/EmptyStates/EmptyState.component';
import BetActivityCard from './BetActivityCard.component';
import { State } from '#/utils/slices/activityFiltersSlice';
import useBackendUserData from '#/utils/dataLoaders/useBackendUserData';
import useIntersectionObserver from '#/hooks/useIntersectionObserver';

interface BetActivityCardsProps {
  searchInput: string;
  activeSubItem: string;
}

const BetActivityCards: React.FC<BetActivityCardsProps> = ({
  searchInput,
  activeSubItem,
}) => {
  const { data: user } = useBackendUserData();
  const auth0Id = user?.id;
  const sgeAddress = user?.walletAddr;

  const { outcomeFilters, sports, marketTypeIds: marketTypes } = useSelector((state: { activityFilters: State }) => state.activityFilters);

  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteBetsDataLoader({
    search: searchInput,
    filters: { outcomes: outcomeFilters, sports, marketTypes },
    userId: auth0Id || '',
    sgeAddress: sgeAddress || '',
  });

  const bets = data ? data.pages.flatMap((page) => page.bets) : [];

  const handleIntersection = () => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };

  const targetRef = useIntersectionObserver({
    onIntersect: handleIntersection,
    root: null,
    rootMargin: '200px',
    threshold: 0.1,
    enabled: true,
  });

  if (isLoading && !bets.length) {
    return <LoadingComponent />;
  }

  if (isError) {
    return <div>Error loading bets.</div>;
  }

  if (!bets.length && !isLoading) {
    return <EmptyList id="bets" />;
  }

  return (
    <div>
      {bets.map((bet) => (
        <BetActivityCard key={bet.id} bet={bet} activeSubItem={activeSubItem} />
      ))}
      <div ref={targetRef} style={{ height: '1px' }}></div>
      {isFetchingNextPage && <LoadingComponent />}
    </div>
  );
};

export default BetActivityCards;
