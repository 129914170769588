import Button from '#/components/common/Buttons/Button/Button.component';
import {
  CardContainer,
  CardHeader,
  InnerContainer,
  DisconnectBtn,
  AddressText,
  AvailBalance,
  DecimalText,
  AvailBalanceText,
  RewardsBalance,
  TotalDecimalText,
  CardFooter,
  IconContainer,
  CardFooterIcon,
  CopyIcon,
  FlexWrapper,
  RewardsBalanceText,
} from './WalletDetailsCard.styled';
import {
  COPY_ICON,
  DEPOSIT_ICON,
  FILE_ICON,
} from '#/constants/common/images.constants';
import RedirectLink from '#/components/common/RedirectLink.component';
import AnimatedModal from '../common/AnimatedModal.component';
import { copyToClipboard } from '#/utils/string/string';
import { toast } from 'react-toastify';
import { AppToast } from '../common/ToastNotification/ToastConfig';
import { convertToSge } from '#/utils/sgeUtils/sgeUtils';
import useBalance from '#/hooks/useBalance';
import useCosmosKitWallet from '#/hooks/useCosmosKitWallet';
import useRewardsDataLoader from '#/utils/dataLoaders/useRewardBalance';

const WalletDetailsCard = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (item: boolean) => void;
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <AnimatedModal title="" isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <WalletDetailsContents />
    </AnimatedModal>
  );
};

export default WalletDetailsCard;

export function WalletDetailsContents() {
  const { account, disconnect } = useCosmosKitWallet();
  const address = account?.address;
  const { data: balance } = useBalance();
  const { data: rewardBalance } = useRewardsDataLoader();

  async function copyAddress(address: string) {
    await copyToClipboard(address);
    toast.error(<AppToast id="AddressCopiedSuccess" />, { icon: false });
  }
  const balanceSge = convertToSge(balance?.amount || '0').split('.');
  const rewardBalanceSge = (rewardBalance?.balance.toString() || '0').split(
    '.',
  );

  const totalBalance =
    +convertToSge(balance?.amount || '0') + (rewardBalance?.balance || 0);
  const totalBalanceSge = (totalBalance.toString() || '0').split('.');
  return (
    <div className={CardContainer}>
      <div className={CardHeader}>
        <div className={FlexWrapper}>
          <RedirectLink
            className={AddressText}
            href=""
            target="_blank"
            rel="noreferrer"
            dataId="sportsbook_transaction_explorer_link"
          >
            {address
              ? `${address.slice(0, 10)}....${address.slice(
                  address.length - 5,
                  address.length,
                )}`
              : '-'}
          </RedirectLink>
          <Button onClick={() => address && copyAddress(address)} dataId="copy_wallet_address">
            <img className={CopyIcon} src={COPY_ICON} alt="Copy icon" />
          </Button>
        </div>
        <div>
          <Button
            className={DisconnectBtn}
            dataId="wallet_modal_disconnect_click"
            onClick={disconnect}
          >
            Disconnect
          </Button>
        </div>
      </div>
      <>
        <div>
          <div className={AvailBalance}>
            <span>
              {balanceSge[0]}
              {balanceSge.length > 1 ? (
                <span className={DecimalText}>
                  .{balanceSge[1].slice(0, 4)}
                </span>
              ) : null}
            </span>
            <span className="ml-1">SGE</span>
          </div>
          <div className={AvailBalanceText}>Available balance</div>
        </div>
        <div className={InnerContainer}>
          <div className="mb-6 px-4 pt-2">
            <div className={RewardsBalance}>
              <span>
                {rewardBalanceSge[0]}
                {rewardBalanceSge.length > 1 ? (
                  <span className={TotalDecimalText}>
                    .{rewardBalanceSge[1].slice(0, 4)}
                  </span>
                ) : null}
              </span>
              <span>SGE</span>
            </div>
            <div className={`${RewardsBalanceText} !px-0`}>
              Rewards (*R) balance
            </div>
          </div>
          <div className="px-4 py-4">
            <div className={RewardsBalance}>
              <span>
                {totalBalanceSge[0]}
                {totalBalanceSge.length > 1 ? (
                  <span className={TotalDecimalText}>
                    .{totalBalanceSge[1].slice(0, 4)}
                  </span>
                ) : null}
              </span>
              <span className="ml-1">SGE</span>
            </div>
            <div className={`${RewardsBalanceText} !px-0`}>Total balance</div>
          </div>
          <div className='w-full'>
            <div className={CardFooter}>
              <RedirectLink className={IconContainer} href={'/deposit'} dataId="deposit_link">
                <img
                  className={CardFooterIcon}
                  src={DEPOSIT_ICON}
                  alt="Deposit icon"
                />
                <span>Deposit</span>
              </RedirectLink>
              <RedirectLink className={IconContainer} href={'/transactions'} dataId="transactions_link">
                <img
                  className={CardFooterIcon}
                  src={FILE_ICON}
                  alt="Transactions icon"
                />
                <span>Transactions</span>
              </RedirectLink>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}
