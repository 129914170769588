import React, { useCallback, useEffect, useState } from 'react';
import Button from '#/components/common/Buttons/Button/Button.component';
import {
  CHECK_CIRCLE_ICON,
  CLOSE_ICON,
  INPROGRESS_CIRCLE_ICON,
  RADIO_CIRCLE_ICON,
} from '#/constants/common/images.constants';
import {
  Container,
  FlexContainer,
  ConnectButton,
  HeadTitle,
  StepsText,
  SubTitle,
  ContentText,
  ContentWrapper,
  SetupButton,
  CheckCircleIcon,
  BorderWrapper,
  DetailsContainer,
  Separator,
  StepsBorder,
  InactiveText,
} from './GetStarted.styled';
import {
  CONNECT_WALLET,
  GET_STARTED_TEXT,
  SETUP_WALLET_TEXT,
} from './GetStarted.constants';
import WalletTutorials from '../WalletTutorials/WalletTutorials.component';
import KycInfo from '../KycInfo/KycInfo.component';
import CenterModal from '#/components/common/CenterModal.component';
import { Synaps } from '@synaps-io/verify-sdk';
import env from '#/utils/env';
import { toast } from 'react-toastify';
import { AppToast } from '../../common/ToastNotification/ToastConfig';
import { setIsModalOpen } from '#/utils/slices/walletConnectionSlice';
import { useAppDispatch } from '#/utils/store';
import useCosmosKitWallet from '#/hooks/useCosmosKitWallet';
import useBackendUserData, { queryKey } from '#/utils/dataLoaders/useBackendUserData';
import useAccessToken from '#/utils/dataLoaders/useAccessToken';
import useAuth0UserData from '#/utils/dataLoaders/useAuth0UserData';
import { connectWallet, startKyc } from '#/utils/backend';
import { useQueryClient } from '@tanstack/react-query';

const GetStarted = () => {
  const { data : user, refetch : fetchUser } = useBackendUserData()
  const { data : accessToken } = useAccessToken()
  const { data : auth0User } = useAuth0UserData()

  const { account, disconnect } = useCosmosKitWallet();
  const address = account?.address;
  const dispatch = useAppDispatch();

  const queryClient = useQueryClient()

  const [showWalletTutorials, setShowWalletTutorials] = useState(false);
  const [showKyc, setShowKyc] = useState(false);
  const [showKycInfo, setShowKycInfo] = useState(false);

  const initSynaps = useCallback(
    (sessionId: string) => {
      Synaps.init({
        sessionId,
        onFinish: fetchUser,
        mode: 'embed',
        containerId: 'kyc',
      });
    },
    [fetchUser],
  );

  const handleStartKyc = useCallback(() => {
    if (user?.kycSessionId) {
      initSynaps(user.kycSessionId);
      return;
    }

    if(!accessToken) {
      return
    }

    const controller = new AbortController()

    startKyc(accessToken, controller)
      .then((backendUser) => {
        queryClient.setQueryData(
          queryKey(backendUser.id),
          backendUser
        )

        if(backendUser.kycSessionId) {
          initSynaps(backendUser.kycSessionId);
        }
      });
  }, [accessToken, initSynaps, user?.kycSessionId]);

  useEffect(() => {
    if (!user || user?.walletAddr || !address || !accessToken) {
      return;
    }

    const controller = new AbortController();

    connectWallet(accessToken, address, controller)
      .then((backendUser) => {
        queryClient.setQueryData(
          queryKey(backendUser.id),
          backendUser
        )

        toast.success(<AppToast id="OnboardingCompleted" />, { icon: false });
      })
      .catch(() => {
        toast.error(<AppToast id="InvalidWallet" />, { icon: false });

        disconnect();
      })

    return () => {
      controller.abort();
    };
  }, [
    address,
    disconnect,
    accessToken,
    connectWallet,
    user?.walletAddr,
  ]);

  useEffect(() => {
    if (showKyc) {
      handleStartKyc();
    }
  }, [showKyc, handleStartKyc]);

  if (!user) {
    return null;
  }

  const isEmailVerified = auth0User ? !!auth0User.email_verified : false;
  const isWalletConnected = Boolean(user.walletAddr);
  const kycStatus = user.kycStatus;

  const steps = [
    {
      title: 'Verify Email',
      icon: isEmailVerified ? CHECK_CIRCLE_ICON : INPROGRESS_CIRCLE_ICON,
      status: isEmailVerified ? 'Done' : 'PENDING',
      content: isEmailVerified
        ? undefined
        : 'You need to verify your email by clicking the link we sent you in your inbox.',
      isActive: true,
      isCompleted: isEmailVerified,
    },
    {
      title: 'KYC Verification',
      icon:
        kycStatus === 'APPROVED'
          ? CHECK_CIRCLE_ICON
          : isEmailVerified
            ? INPROGRESS_CIRCLE_ICON
            : RADIO_CIRCLE_ICON,
      status:
        isEmailVerified && kycStatus
          ? {
              SUBMISSION_REQUIRED: 'Submission Required',
              PENDING_VERIFICATION: 'Pending',
              APPROVED: 'Done',
              RESUBMISSION_REQUIRED: 'Resubmission Required',
              REJECTED: 'Rejected',
            }[kycStatus]
          : undefined,
      content:
        isEmailVerified && kycStatus === 'PENDING_VERIFICATION'
          ? 'Your KYC has been successfully submitted! Check back later to see the status.'
          : isEmailVerified && kycStatus === 'REJECTED'
            ? 'We were not able to verify your identity from the documents you have submitted. If you believe this is an error, please contact info@sixsigmasports.io. Only KYC approved users have full access to our platform.'
            : isEmailVerified && kycStatus !== 'APPROVED'
              ? 'You need to go through our KYC (Know Your Customer) process to ensure a trusted environment for all users.'
              : undefined,
      buttons:
        isEmailVerified &&
        kycStatus !== 'PENDING_VERIFICATION' &&
        kycStatus !== 'APPROVED' &&
        kycStatus !== 'REJECTED'
          ? [
              {
                text: 'KYC Verification',
                className: `${ConnectButton} mb-3 md:mb-0`,
                onClick: () => setShowKyc(true),
              },
              {
                text: 'Learn More about KYC',
                className: SetupButton,
                onClick: () => setShowKycInfo(!showKycInfo),
              },
            ]
          : undefined,
      isActive: isEmailVerified,
      isCompleted: kycStatus === 'APPROVED',
    },
    {
      title: 'Connect Wallet',
      icon: isWalletConnected
        ? CHECK_CIRCLE_ICON
        : kycStatus === 'APPROVED'
          ? INPROGRESS_CIRCLE_ICON
          : RADIO_CIRCLE_ICON,
      status: isWalletConnected
        ? 'Done'
        : kycStatus === 'APPROVED'
          ? 'PENDING'
          : undefined,
      content:
        kycStatus === 'APPROVED' && !isWalletConnected
          ? 'You need to make a wallet to store your cryptocurrency winnings securely.'
          : undefined,
      buttons:
        kycStatus === 'APPROVED' && !isWalletConnected
          ? [
              {
                text: CONNECT_WALLET,
                className: `${ConnectButton} mb-3 md:mb-0`,
                onClick: () => dispatch(setIsModalOpen(true)),
              },
              {
                text: SETUP_WALLET_TEXT,
                className: SetupButton,
                onClick: () => setShowWalletTutorials(!showWalletTutorials),
              },
            ]
          : undefined,
      isActive: kycStatus === 'APPROVED',
      isCompleted: isWalletConnected,
    },
  ];

  const currentStep = isEmailVerified
    ? kycStatus === 'APPROVED'
      ? isWalletConnected
        ? 3
        : 2
      : 2
    : 1;

  return (
    <div className="px-4 pb-4 3xl:pb-6 4xl:pb-8">
      <div className={Container}>
        <div className={FlexContainer}>
          <h2 className={HeadTitle}>
            <span>{GET_STARTED_TEXT}</span>
          </h2>
          <span className={StepsText}>STEP {currentStep}/3</span>
        </div>
        <div className={BorderWrapper}>
          {[1, 2, 3].map((step) => (
            <div
              key={step}
              className={StepsBorder({ isActive: currentStep >= step })}
            ></div>
          ))}
        </div>
      </div>

      <div className={DetailsContainer}>
        {steps.map((step, index) => (
          <React.Fragment key={index}>
            <div className={Container}>
              <div className={FlexContainer}>
                <h3 className={SubTitle}>
                  <span>
                    <img
                      className={CheckCircleIcon}
                      src={step.icon}
                      alt="Icon"
                    />
                  </span>
                  <span
                    className={`${step.isActive ? undefined : InactiveText} ${step.status === 'Done' ? 'line-through' : ''}`}
                  >
                    {step.title}
                  </span>
                </h3>
                {step.status && (
                  <span className={StepsText}>{step.status}</span>
                )}
              </div>
              {step.content && (
                <div className={ContentWrapper}>
                  <p className={ContentText}>{step.content}</p>
                </div>
              )}
              {step.buttons && (
                <div className="mt-4 sm:space-x-4 md:flex md:items-center md:justify-end">
                  {step.buttons.map((button, buttonIndex) => (
                    <Button
                      key={buttonIndex}
                      className={button.className}
                      onClick={button.onClick}
                      dataId={`onboarding_step_${step.title.toLowerCase().replaceAll(" ", "_")}`}
                    >
                      {button.text}
                    </Button>
                  ))}
                </div>
              )}
            </div>
            {index < steps.length - 1 && <div className={Separator} />}
          </React.Fragment>
        ))}
      </div>

      {showWalletTutorials && (
        <WalletTutorials
          isOpen={showWalletTutorials}
          onClose={() => setShowWalletTutorials(false)}
        />
      )}

      {showKycInfo && (
        <KycInfo isOpen={showKycInfo} onClose={() => setShowKycInfo(false)} />
      )}

      <CenterModal isOpen={showKyc}>
        <div className="relative bg-white">
          <div className="absolute top-[20px] left-[20px] z-1 w-[46px] h-[46px] bg-white flex justify-center">
            <Button onClick={() => setShowKyc(false)} dataId="close_kyc">
              <img className="w-6 invert" src={CLOSE_ICON} alt="Close Icon" />
            </Button>
          </div>
          <div id="kyc" style={{ height: '100vh' }}></div>
        </div>
      </CenterModal>
    </div>
  );
};

export default GetStarted;
