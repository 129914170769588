import { useSearchParams } from 'react-router-dom';
import { TabsSubItems, TabsWrapper } from './ActivityTab.styled';
import Button from '#/components/common/Buttons/Button/Button.component';

type SubTabsProps = {
  activeTab: string;
};

export function SubTabs({ activeTab }: SubTabsProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const activeSubItem =
    searchParams.get('subItem') ||
    (activeTab === 'betActivity' ? 'active' : 'engagement');

  const handleSubItemClick = (subItem: string) => {
    searchParams.set('subItem', subItem);
    setSearchParams(searchParams);
  };

  return (
    <>
      {activeTab === 'betActivity' && (
        <div className={`${TabsWrapper} mb-4`}>
          <Button
            className={TabsSubItems({
              isActive: activeSubItem === 'active',
            })}
            onClick={() => handleSubItemClick('active')}
            dataId="active_bet_activity_tab"
          >
            <span>Active Bets</span>
          </Button>
          <Button
            className={TabsSubItems({
              isActive: activeSubItem === 'settled',
            })}
            onClick={() => handleSubItemClick('settled')}
            dataId="settled_bet_activity_tab"
          >
            <span>Settled Bets</span>
          </Button>
        </div>
      )}

      {activeTab === 'houseActivity' && (
        <div className={`${TabsWrapper} mb-4`}>
          <Button
            className={TabsSubItems({
              isActive: activeSubItem === 'active',
            })}
            onClick={() => handleSubItemClick('active')}
            dataId="active_house_activity_tab"
          >
            <span>Active</span>
          </Button>
          <Button
            className={TabsSubItems({
              isActive: activeSubItem === 'settled',
            })}
            onClick={() => handleSubItemClick('settled')}
            dataId="settled_house_activity_tab"
          >
            <span>Settled</span>
          </Button>
        </div>
      )}
    </>
  );
}
