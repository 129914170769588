import { useQuery, useQueryClient } from '@tanstack/react-query';
import { HouseProvision, loadLiquidityData } from '../backend';
import { useExecute } from '#/hooks/useExecute';
import { useKycStatus } from '#/components/SlipsDrawers/Components/useKycStatus';
import useBackendUserData from './useBackendUserData';

const useLiquidityDataLoader = () => {
  const queryClient = useQueryClient();
  const { executeMultiple } = useExecute();
  const { kycToken } = useKycStatus(true);

  const { data : user } = useBackendUserData()
  const sgeAddress = user?.walletAddr
  const userId = user?.id

  const query = useQuery({
    queryKey: [`liquidityDataLoader`, userId],
    // Only once the user has the sgeAddress stored, it means he/she is onboarded
    // and the backend is aware of the id
    enabled: !!userId && !!sgeAddress,
    queryFn: () => {
      if (userId && sgeAddress) {
        return loadLiquidityData(userId);
      }
    },
  });

  const withdraw = async (marketId: string, serial: number) => {
    if (!userId) {
      return;
    }

    const msg = {
      msg: {
        house_withdraw_liquidity: {
          token: kycToken,
          market_id: marketId,
          serial: serial.toString(),
        },
      },
      funds: [],
    };

    try {
      await executeMultiple([msg], {
        onSuccess: () => {
          queryClient.setQueryData<HouseProvision[]>(
            [`liquidityDataLoader`, userId],
            (oldData) => {
              if (!oldData) return oldData;
              return oldData.map((provision) =>
                provision.marketId.toString() === marketId
                  ? { ...provision, withdrawnAmount: provision.amount }
                  : provision,
              );
            },
          );
        },
        onError: () => {
          // Revert optimistic update on error
          queryClient.invalidateQueries({
            queryKey: [`liquidityDataLoader`, userId],
          });
        },
      });
    } catch (error) {
      queryClient.invalidateQueries({
        queryKey: [`liquidityDataLoader`, userId],
      });
      throw error;
    }
  };

  return {
    ...query,
    withdraw,
  };
};

export default useLiquidityDataLoader;
