import { useEffect, useRef } from 'react';

interface UseIntersectionObserverProps {
  onIntersect: () => void;       // Function to call when the element is intersecting
  root?: Element | null;         // The element that is used as the viewport for checking visibility
  rootMargin?: string;           // Margin around the root (to trigger earlier or later)
  threshold?: number | number[]; // Percentage of target's visibility to trigger observer
  enabled?: boolean;             // Whether the observer should be enabled
}

const useIntersectionObserver = ({
  onIntersect,
  root = null,               // Default: viewport
  rootMargin = '0px',        // Default margin
  threshold = 0.1,           // Default: trigger when 10% of the element is visible
  enabled = true             // Default: observer is enabled
}: UseIntersectionObserverProps) => {
  const observerRef = useRef<IntersectionObserver | null>(null);
  const targetRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!enabled || !targetRef.current) return; // Return if the observer is disabled or target is missing

    // Create the intersection observer
    observerRef.current = new IntersectionObserver(
      (entries) => {
        const [entry] = entries; // Only one entry in our case (for target)
        if (entry?.isIntersecting) {
          onIntersect(); // Call the provided callback when the target is in view
        }
      },
      {
        root,
        rootMargin,
        threshold,
      }
    );

    // Start observing the target element
    const currentObserver = observerRef.current;
    currentObserver.observe(targetRef.current);

    // Cleanup when the component unmounts or dependencies change
    return () => {
      if (currentObserver && targetRef.current) {
        currentObserver.unobserve(targetRef.current);
      }
    };
  }, [onIntersect, root, rootMargin, threshold, enabled]);

  return targetRef; // Return the ref to be attached to the target element
};

export default useIntersectionObserver;
