import { ContentContainer, TableColGap, TableHead } from './Transactions.styled';
import { Transaction, TransactionsData } from '#/utils/backend';
import TransactionCard, { TransactionTable } from './TransactionCard.component';
import WithData from '../Wrapper/WrapWithTransitionStates';
import {
  LoaderBg,
  LoaderBtn,
  LoaderCardContainer,
  LoaderSeparator,
} from '../common/LoaderStyle/LoaderStyle.styled';
import EmptyList from '../common/EmptyStates/EmptyState.component';

interface TransactionsProps {
  data: Transaction[];
}

function TransactionsWithData({ data }: TransactionsProps) {
  if (!data || data.length === 0)
    return (
      <div>
        <EmptyList id="transactions" />
      </div>
    );
  return (
    <div className={`${ContentContainer} mt-4`}>
      <div className='lg:hidden'>
        {data.map((transaction) => (
          <TransactionCard transaction={transaction} key={transaction.hash} />
        ))}
      </div>

      <div className='hidden lg:block w-full TableStyle'>
        <table className="w-full">
          <thead className={TableHead}>
            <tr>
              <th className={TableColGap}>Transaction Hash</th>
              <th className={TableColGap}>Bet Type</th>
              <th className={TableColGap}>Height</th>
              <th className={TableColGap}>Status</th>
              <th className={TableColGap}>Date & Time</th>
            </tr>
          </thead>
          <tbody>
            {data.map((transaction) => (
              <TransactionTable transaction={transaction} key={transaction.hash} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

const LoadingComponent = () => {
  return (
    <div className="mt-4">
      <div className={LoaderCardContainer}>
        <div>
          <div className="flex justify-between mb-4">
            <div className={`${LoaderBg} h-6 w-24`}></div>
            <div className={`${LoaderBg} h-6 w-20`}></div>
          </div>
          <div className="flex mb-4">
            <div className={`${LoaderBg} h-5 w-2/5`}></div>
            <div className={`${LoaderBg} h-5 w-10 ml-4`}></div>
          </div>

          <div className="flex mb-4">
            <div className='flex-1'>
              <div className={`${LoaderBg} h-5 w-20`}></div>
            </div>
            <div className='flex-1'>
              <div className={`${LoaderBg} h-5 w-24`}></div>
            </div>
          </div>
        </div>
        <div className={LoaderSeparator} />
        <div>
          <div className="flex justify-between mb-4">
            <div className={`${LoaderBg} h-6 w-24`}></div>
            <div className={`${LoaderBg} h-6 w-20`}></div>
          </div>
          <div className="flex mb-4">
            <div className={`${LoaderBg} h-5 w-2/5`}></div>
            <div className={`${LoaderBg} h-5 w-10 ml-4`}></div>
          </div>

          <div className="flex mb-4">
            <div className='flex-1'>
              <div className={`${LoaderBg} h-5 w-20`}></div>
            </div>
            <div className='flex-1'>
              <div className={`${LoaderBg} h-5 w-24`}></div>
            </div>
          </div>
        </div>
        <div className={LoaderSeparator} />
        <div>
          <div className="flex justify-between mb-4">
            <div className={`${LoaderBg} h-6 w-24`}></div>
            <div className={`${LoaderBg} h-6 w-20`}></div>
          </div>
          <div className="flex mb-4">
            <div className={`${LoaderBg} h-5 w-2/5`}></div>
            <div className={`${LoaderBg} h-5 w-10 ml-4`}></div>
          </div>

          <div className="flex mb-4">
            <div className='flex-1'>
              <div className={`${LoaderBg} h-5 w-20`}></div>
            </div>
            <div className='flex-1'>
              <div className={`${LoaderBg} h-5 w-24`}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Transactions = WithData<TransactionsData>(
  TransactionsWithData,
  LoadingComponent,
);

export default Transactions;
