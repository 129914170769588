import { useSearchParams } from 'react-router-dom';
import { ActivityTabIcon, TabsItems, TabsWrapper } from './ActivityTab.styled';
import {
  BETS_ICON,
  BETS_ICON_DARK,
  HOUSE_ICON_DARK,
  HOUSE_ICON_LIGHT,
} from '#/constants/common/images.constants';
import Button from '#/components/common/Buttons/Button/Button.component';

export default function ActivityTab() {
  const [searchParams, setSearchParams] = useSearchParams();

  const activeTab = searchParams.get('tab') || 'betActivity';

  const handleTabClick = (tab: string) => {
    setSearchParams({
      tab,
      subItem: tab === 'betActivity' ? 'active' : 'active',
    });
  };

  return (
    <>
      <div className={`${TabsWrapper} mb-4`}>
        <Button
          className={TabsItems({ isActive: activeTab === 'betActivity' })}
          onClick={() => handleTabClick('betActivity')}
          dataId="bet_activity_tab"
        >
          <span>
            <img
              className={ActivityTabIcon}
              src={activeTab === 'betActivity' ? BETS_ICON_DARK : BETS_ICON}
              alt="Bet Activity Icon"
            />
          </span>
          <span>Bet Activity</span>
        </Button>
        <Button
          className={TabsItems({ isActive: activeTab === 'houseActivity' })}
          onClick={() => handleTabClick('houseActivity')}
          dataId="house_activity_tab"
        >
          <span>
            <img
              className={ActivityTabIcon}
              src={
                activeTab === 'houseActivity'
                  ? HOUSE_ICON_DARK
                  : HOUSE_ICON_LIGHT
              }
              alt="House Activity Icon"
            />
          </span>
          <span>House Activity</span>
        </Button>
      </div>
    </>
  );
}
