import MobileCapacityToast from '#/components/MatchCard/MobileCapacityToast';
import { useState } from 'react';
import ActionTag from '#/components/common/ActionTags.component';
import {
  DEFAULT_ODD_TYPE,
  MIN_BET_SLIP_AMOUNT,
  MIN_HOUSE_SLIP_AMOUNT,
} from '#/constants/common/global-constants';
import OddButton from '#/components/common/Buttons/OddButton/OddButton.component';
import {
  CardContainer,
  MultipleOddsContainer,
  MultipleOddsWrapper,
  OddHeaderMultiple,
} from './MarketCard.styled';
import type { MarketCardProps } from './MarketCard.types';
import HouseParams from './HouseParams/HouseParams.component';
import { OddLabelButtonStyleMobile } from '#/components/common/Buttons/OddButton/OddButton.styled';
import { getDisplayOddValue } from '#/utils/appUtils';
import { getMarketNameById, getOutcomeName } from '#/utils/marketsMap';
import {
  OddsContainer,
  OddNameMobile,
} from '../common/MarketComponent/DashboardMarket.styled';
import {
  ODDS_DOWN_ARROW,
  ODDS_UP_ARROW,
} from '#/constants/common/images.constants';
import CardCollapse from './CardCollapse.component';
import { useAppSelector } from '#/utils/store';
import { MarketLiveData, OutcomeLiveData, outcomeId } from '#/utils/backend';
import { isOddSelected } from '../SlipsDrawers/SlipsDrawer.utils';
import { stringToBigdecimalOrZero } from '#/utils/bigdecimal';

export default function MarketCard({
  market,
  expanded,
  setExpandedCard,
  addBetSlip,
  addHouseSlip,
}: MarketCardProps) {
  const oddsType = String(DEFAULT_ODD_TYPE.key);
  const [showCapacityMessage, setShowCapacityMessage] = useState(false);
  const totalOdds = Object.keys(market.odds).length;

  const markets = useAppSelector((state) => state.markets.markets);

  const marketData : MarketLiveData | undefined = markets[market.id];
  const marketStatus = marketData ? marketData.status : 'Aborted';
  const acceptableProvisionSize = stringToBigdecimalOrZero(marketData ? marketData.acceptable_provision_size : '0')

  const isMarketDisabled =
    marketStatus !== 'Operational' ||
    acceptableProvisionSize.lowerThan(
      MIN_HOUSE_SLIP_AMOUNT,
    );

  const outcomes = useAppSelector((state) => state.outcomes.outcomes);
  const betSlips = useAppSelector((state) => state.betSlips);

  return (
    <div className={CardContainer}>
      <ActionTag
        type="div"
        className={MultipleOddsWrapper}
        onClick={() => {
          return;
        }}
        dataId="match_details_mutiple_odds_collapse_card_click"
      >
        <CardCollapse
          index={0}
          isCollapsed={expanded}
          onToggle={() => {
            setExpandedCard(!expanded);
          }}
          title={
            <span
              className={OddHeaderMultiple}
            >{`${getMarketNameById(market.marketTypeId) || market.marketTypeName} ${market.betLine ? market.betLine : ''}`}</span>
          }
        >
          {showCapacityMessage ? <MobileCapacityToast /> : null}

          <div
            className={MultipleOddsContainer({
              hasMoreThanTwoOdds: totalOdds > 2,
              $isDisabled: isMarketDisabled,
            })}
          >
            {Object.keys(market.odds).map((oddKey) => {
              const odd = market.odds[oddKey];
              const outcomeData : OutcomeLiveData | undefined = outcomes[outcomeId(odd)];

              const value = outcomeData?.value || '-';
              const movement = outcomeData?.movement || 'Down';
              const acceptableBetSize = stringToBigdecimalOrZero(outcomeData ? outcomeData.acceptable_bet_size : '0')

              const isSelected = isOddSelected(betSlips, odd);
              const isOddDisabled =
                acceptableBetSize.lowerThan(MIN_BET_SLIP_AMOUNT) || false;
              return (
                <div className="w-full" key={odd.outcomePosition}>
                  <OddButton
                    value={getDisplayOddValue({
                      oddsType: String(1) || DEFAULT_ODD_TYPE.key,
                      oddValues: {
                        decimal: value,
                        american: '',
                        fractional: '',
                      },
                      extended: true,
                    })}
                    extendedValue={getDisplayOddValue({
                      oddsType: String(1) || DEFAULT_ODD_TYPE.key,
                      oddValues: {
                        decimal: value,
                        american: '',
                        fractional: '',
                      },
                      extended: true,
                    })}
                    onClick={() =>
                      !isMarketDisabled
                        ? addBetSlip(market, { ...odd, value })
                        : setShowCapacityMessage(true)
                    }
                    oddsType={oddsType}
                    name={
                      getOutcomeName(
                        odd.marketTypeId,
                        odd.outcomePosition,
                        market.teams.Home.Name,
                        market.teams.Away.Name,
                      ) || odd.outcomeName
                    }
                    oddContainerStyle={OddsContainer}
                    oddLabelStyle={OddNameMobile}
                    withLabel={true}
                    OddValueStyledMobile={OddLabelButtonStyleMobile({
                      $isSelected: isSelected,
                      $isDisabled: isOddDisabled,
                    })}
                    icon={
                      movement
                        ? movement === 'Up'
                          ? ODDS_UP_ARROW
                          : ODDS_DOWN_ARROW
                        : ''
                    }
                  />
                </div>
              );
            })}
          </div>
          <HouseParams addHouseSlip={addHouseSlip} market={market} />
        </CardCollapse>
      </ActionTag>
    </div>
  );
}
