import { useQuery } from '@tanstack/react-query';
import { loadBets } from '../backend';
import useBackendUserData from './useBackendUserData';
import { MINUTE } from '../time';

const useBetsDataLoader = () => {
  const { data : user } = useBackendUserData()

  const sgeAddress = user?.walletAddr
  const userId = user?.id

  return useQuery({
    queryKey: ['betsDataLoader', userId],
    // Only once the user has the sgeAddress stored, it means he/she is onboarded
    // and the backend is aware of the id
    enabled: !!userId && !!sgeAddress,
    queryFn: () => {
      if (userId && sgeAddress) {
        return loadBets(userId);
      }
      return null;
    },
    refetchInterval: MINUTE
  });
};

export default useBetsDataLoader;
