import { useState } from 'react';
import Transactions from '#/components/Transactions/Transactions.component';
import useTransactionsDataLoader from '#/utils/dataLoaders/useTransactionsLoader';
import { SEARCH_ICON_LIGHT } from '#/constants/common/images.constants';
import { DropDownInnerWrapper, DropDownStyle, FilterBtn, FiltersWrapper, Header, PageTitle } from './Transactions/Transactions.styled';
import FilterButton from '#/components/common/FilterButton.component';
import { SearchInputField, SearchInputFieldWrapper, SearchLeftIcon } from './common/Search/Search.styled';
import TransactionsFilter from './Transactions/TransactionsFilter.component';
import { AppToast } from './common/ToastNotification/ToastConfig';
import { toast } from 'react-toastify';
import useCosmosKitWallet from '#/hooks/useCosmosKitWallet';
import StatusFilter from './Transactions/StatusFilter.component';
import TypeFilter from './Transactions/TypeFilter.component';
import Pagination from './Transactions/Pagination';

export default function TransactionsList() {
  const pageSize = 100; // Number of transactions per page
  const [showFilters, setShowFilters] = useState(false);
  const { account } = useCosmosKitWallet();
  const { data, isLoading, error } = useTransactionsDataLoader({
    pageSize,
    address: account?.address,
  });

  if (error) {
    toast.error(<AppToast id={'UnableToFetch'} />, { icon: false });
  }

  const [showTypeDropdown, setShowTypeDropdown] = useState(false);
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);

  const toggleTypeDropdown = () => {
    setShowTypeDropdown((prev) => !prev);
    setShowStatusDropdown(false);
  };

  const toggleStatusDropdown = () => {
    setShowStatusDropdown((prev) => !prev);
    setShowTypeDropdown(false);
  };
  return (
    <div className="px-4">
      <div className={Header}>
        <h2 className={PageTitle}>Transactions</h2>
      </div>
      {/* TODO later */}
      <div className="flex items-center">
        <div className={SearchInputFieldWrapper}>
          <img
            className={SearchLeftIcon}
            src={SEARCH_ICON_LIGHT}
            alt="icon"
            loading="lazy"
          />
          <input
            className={SearchInputField}
            aria-label={'Search'}
            type="text"
            placeholder="Search"
            autoComplete="off"
          />

        </div>
        <div className='lg:hidden'> <FilterButton onClick={() => setShowFilters(!showFilters)} /></div>
        <div className={FiltersWrapper}>
          <span className='relative'>
            <span className={FilterBtn} onClick={toggleStatusDropdown}>
              Status
            </span>
            {showStatusDropdown && (
              <div className={DropDownStyle}>
                <div className={DropDownInnerWrapper}>
                  <StatusFilter />
                </div>
              </div>
            )}
          </span>
          <span className='relative'>
            <span className={FilterBtn} onClick={toggleTypeDropdown}>Bet Type</span>
            {showTypeDropdown && (
              <div className={DropDownStyle}>
                <div className={DropDownInnerWrapper}>
                  <TypeFilter />
                </div>
              </div>
            )}
          </span>
        </div>

      </div>
      <Transactions data={data} isLoading={isLoading || error} id={'data'} />
      <div>
        <Pagination />
      </div>
      {showFilters ? (
        <TransactionsFilter
          isOpen={showFilters}
          onClose={() => setShowFilters(false)}
        />
      ) : null}

    </div>
  );
}
