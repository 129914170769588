import { useEffect } from 'react';
import { LicenceLogo } from './Footer.styled';

interface AnjSealProps {
  imageSize?: string;
}

declare global {
  interface Window {
    anj_bf2028e4_3018_4fef_a511_ddb17fa0f60f?: {
      init: () => void;
    };
  }
}

export default function AnjSeal({ imageSize = '32px' }: AnjSealProps) {
  const sealId =
    '04df4430bbf84f8f48ec29aa83bba6551ffa0b17c48fd9a813d11aaac25e55c9530130c9a02840113cac0b8a110061f2';
  const stamp = 'd78afc65c0a61bf77b9df691c5441793';
  const host = 'sixsigmasports.app';

  useEffect(() => {
    const initSeal = () => {
      if (window.anj_bf2028e4_3018_4fef_a511_ddb17fa0f60f) {
        window.anj_bf2028e4_3018_4fef_a511_ddb17fa0f60f.init();
      }
    };

    if (document.querySelector('script[src*="anj-seal.js"]')) {
      initSeal();
    } else {
      const script = document.createElement('script');
      script.src =
        'https://bf2028e4-3018-4fef-a511-ddb17fa0f60f.snippet.anjouangaming.org/anj-seal.js';
      script.async = true;
      script.onload = initSeal;
      document.body.appendChild(script);
    }
  }, []);

  return (
    <>
      <div
        id="anj-bf2028e4-3018-4fef-a511-ddb17fa0f60f"
        data-anj-seal-id={sealId}
        data-anj-image-size={imageSize}
        data-anj-image-type="basic-small"
        style={{
          display: 'block',
          position: 'relative',
          overflow: 'hidden',
          maxWidth: imageSize,
          minWidth: '32px',
          backgroundImage:
            'url(https://bf2028e4-3018-4fef-a511-ddb17fa0f60f.snippet.anjouangaming.org/54f396e0-b046-49b1-9cb3-0c69281d7ea9-beacon.png)',
        }}
        className={LicenceLogo}
      >
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://verification.anjouangaming.org/validate?domain=${host}&seal_id=${sealId}&stamp=${stamp}`}
        >
          <img
            alt=""
            style={{ width: '100%', height: 'auto' }}
            className="md:w-9 4xl:w-12 5xl:w-20 6xl:w-24"
            src={`https://bf2028e4-3018-4fef-a511-ddb17fa0f60f.snippet.anjouangaming.org/sealassets/${stamp}-${host}-${sealId}-c2VhbC5wbmc%3D?status=valid`}
          />
        </a>
      </div>
    </>
  );
}
