import { useCallback, useEffect, type ChangeEvent } from 'react';
import {
  SearchInputFieldWrapper,
  SearchLeftIcon,
  SearchInputField,
} from './Search.styled';
import type { SearchInputFieldProps } from './Search.types';
import ImgActionTag from '../ImageActionTag.component';
import {
  CLOSE_ICON,
  SEARCH_ICON_LIGHT,
} from '#/constants/common/images.constants';
import FilterButton from '../FilterButton.component';
import { useLocation } from 'react-router-dom';

function Search({
  search,
  setSearch,
  setShowSearchResults,
  showSearchResults,
  onClick,
}: SearchInputFieldProps) {
  const location = useLocation();
  function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    setSearch(value);
  }

  const clearSearchInput = useCallback(() => {
    setSearch('');
  }, [setSearch]);

  useEffect(() => {
    if (location.pathname) {
      clearSearchInput();
    }
  }, [location, clearSearchInput]);

  return (
    <>
      <div className={SearchInputFieldWrapper}>
        <img
          className={SearchLeftIcon}
          src={SEARCH_ICON_LIGHT}
          alt="icon"
          loading="lazy"
        />
        <input
          className={SearchInputField}
          aria-label={'Search'}
          type="text"
          placeholder="Search"
          autoComplete="off"
          onChange={handleInputChange}
          onFocus={() => setShowSearchResults && setShowSearchResults(true)}
          value={search}
        />
        {showSearchResults && search ? (
          <ImgActionTag
            className={SearchLeftIcon}
            onClick={clearSearchInput}
            src={CLOSE_ICON}
            alt="icon"
            loading="lazy"
            dataId="clear_search_image_click"
          />
        ) : null}
      </div>
      {onClick && <FilterButton onClick={onClick} />}
    </>
  );
}

export default Search;
