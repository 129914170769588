import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '#/utils/store';
import { format, addDays } from 'date-fns';
import {
  FavIcon,
  SportListWrapper,
  TagFilterItems,
} from './ScheduleFilters.styled';
import { STAR_ICON } from '#/constants/common/images.constants';
import { useCallback, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { applyFilter } from './ScheduleFilters.utils';

export default function ScheduleFilters() {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { startsAfter, startsBefore, favouritesOf, filters } = useAppSelector(
    (state) => state.search,
  );
  const { user, isAuthenticated, loginWithRedirect } = useAuth0();

  const updateSearchParams = (
    filterType: string,
    setSearchParams: (params: URLSearchParamsInit) => void,
  ): void => {
    const params = new URLSearchParams();
    params.set('filter', filterType);
    setSearchParams(params);
  };

  const handleApplyFilter = useCallback(
    (filterType: string) => {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const filterParam = searchParams.get('filter');
      applyFilter(filterType, dispatch, {
        isAuthenticated,
        user: { sub: user?.sub ?? '' },
        timezone,
        filters,
        filterParam,
        loginWithRedirect,
      });
      updateSearchParams(filterType, setSearchParams);
    },
    [
      dispatch,
      isAuthenticated,
      setSearchParams,
      user?.sub,
      filters,
      searchParams,
      loginWithRedirect,
    ],
  );

  useEffect(() => {
    const filterParam = searchParams.get('filter');
    if (filterParam) {
      handleApplyFilter(filterParam);
    }
  }, [handleApplyFilter, searchParams]);

  const getActiveFilter = () => {
    if (favouritesOf) return 'favourite';
    if (startsAfter && startsBefore) {
      const today = new Date();
      const tomorrow = addDays(today, 1);
      if (startsAfter.startsWith(format(today, 'yyyy-MM-dd'))) return 'today';
      if (startsAfter.startsWith(format(tomorrow, 'yyyy-MM-dd')))
        return 'tomorrow';
    }
    if (startsAfter && !startsBefore) return 'upcoming';
    return '';
  };

  const activeFilter = getActiveFilter();

  return (
    <div className={SportListWrapper}>
      <div
        className={`${TagFilterItems({
          isActive: activeFilter === 'favourite',
        })} flex-none`}
        onClick={() => handleApplyFilter('favourite')}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            handleApplyFilter('favourite');
          }
        }}
      >
        <img
          className={FavIcon}
          loading="lazy"
          src={STAR_ICON}
          alt={'Star Favourites'}
        />
      </div>
      <div
        className={TagFilterItems({
          isActive: activeFilter === 'today',
        })}
        onClick={() => handleApplyFilter('today')}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            handleApplyFilter('today');
          }
        }}
      >
        Today
      </div>
      <div
        className={TagFilterItems({
          isActive: activeFilter === 'tomorrow',
        })}
        onClick={() => handleApplyFilter('tomorrow')}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            handleApplyFilter('tomorrow');
          }
        }}
      >
        Tomorrow
      </div>
      <div
        className={TagFilterItems({
          isActive: activeFilter === 'upcoming',
        })}
        onClick={() => handleApplyFilter('upcoming')}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            handleApplyFilter('upcoming');
          }
        }}
      >
        Upcoming
      </div>
    </div>
  );
}
