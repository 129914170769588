import { useState } from 'react';
import ActivityCard from '#/components/common/ActivityCard/ActivityCard.component';
import { formatDateToDesiredFormat } from '#/utils/datetime/datetime';
import { getStatusTypeHouse, HouseProvision } from '#/utils/backend';
import WithData from './Wrapper/WrapWithTransitionStates';
import {
  LoaderBg,
  LoaderCardContainer,
} from './common/LoaderStyle/LoaderStyle.styled';
import EmptyList from './common/EmptyStates/EmptyState.component';
import { useAppDispatch, useAppSelector } from '#/utils/store';
import { getMarketNameById } from '#/utils/marketsMap';
import { toast } from 'react-toastify';
import { setIsModalOpen } from '#/utils/slices/walletConnectionSlice';
import Button from '#/components/common/Buttons/Button/Button.component';
import useCosmosKitWallet from '#/hooks/useCosmosKitWallet';
import ActionTag from './common/ActionTags.component';
import {
  BeHouseText,
  FlexContainerBeHouse,
  HouseIcon,
} from './MatchCard/MatchCard/MatchCard.styled';
import {
  MINUS_CIRCLE_ICON,
  PLUSH_CIRCLE_ICON,
} from '#/constants/common/images.constants';
import EngagementDetailsCard from './EngagementDetailsCard';
import { CardFlexItem } from './common/ActivityCard/ActivityCard.types';
import useBackendUserData from '#/utils/dataLoaders/useBackendUserData';

const WithdrawBtn = `w-full sm:w-80 tracking-wider rounded-md bg-brand-bg-1 px-4 py-3 text-black font-bold text-sm xxxs:text-basew-full sm:w-80 tracking-wider rounded-md bg-transparent px-4 py-2.5 text-text-brand-1 font-bold text-sm xxxs:text-base text-center border border-border-bg-2 relative`;

interface HouseActivityCardsWithDataProps {
  searchInput: string;
  activeTab: string;
  activeSubItem: string;
  data: HouseProvision[];
  withdraw: (marketId: string, serial: number) => Promise<void>;
}

function HouseActivityCardsWithData({
  searchInput,
  activeSubItem,
  data,
  withdraw,
}: HouseActivityCardsWithDataProps) {
  const activityFilters = useAppSelector((state) => state.activityFilters);
  const { data: user } = useBackendUserData();

  const { account } = useCosmosKitWallet();
  const [withdrawingMarkets, setWithdrawingMarkets] = useState<Set<string>>(
    new Set(),
  );
  const [showEngagementDetails, setShowEngagementDetails] = useState<
    Set<string>
  >(new Set());
  const accountDeactivated = user?.responsibleGambling?.accountDeactivated;
  const dispatch = useAppDispatch();

  const handleWithdraw = async (provision: HouseProvision) => {
    if (accountDeactivated) {
      toast.error('Your account is deactivated. Withdrawal is disabled.');
      return;
    }

    if (!account) {
      dispatch(setIsModalOpen(true));
      return;
    }

    try {
      setWithdrawingMarkets((prev) => new Set(prev.add(provision.id)));
      // Extract and validate the serial from provision.id (format: marketId-serial)
      const [marketIdStr, serialStr] = provision.id.split('-');
      if (!marketIdStr || !serialStr) {
        throw new Error(`Invalid provision ID format: ${provision.id}`);
      }

      const serial = Number(serialStr);
      if (isNaN(serial)) {
        throw new Error(`Serial is not a valid number: ${serialStr}`);
      }

      await withdraw(provision.marketId.toString(), serial);
      toast.success('Withdrawal successful');
    } catch (error) {
      console.error('Error during withdrawal:', error);
      if (error instanceof Error) {
        if (error.message.includes('User cancelled')) {
          return;
        }
        toast.error(`Failed to withdraw: ${error.message}`);
      } else {
        toast.error('An unexpected error occurred during withdrawal');
      }
    } finally {
      setWithdrawingMarkets((prev) => {
        const newSet = new Set(prev);
        newSet.delete(provision.id);
        return newSet;
      });
    }
  };

  const filteredData = data.filter((provision) => {
    const fitsTab =
      activeSubItem === 'active'
        ? provision.state === 'Active'
        : activeSubItem === 'settled'
          ? ['Settled', 'Failed'].includes(provision.state)
          : false;

    const fitsSearch = searchInput
      ? provision.fixtureName.toLowerCase().includes(searchInput.toLowerCase())
      : true;

    const allowedMarketTypeIds = Object.keys(activityFilters.marketTypeIds)
      .filter((id) => activityFilters.marketTypeIds[Number(id)])
      .map(Number);

    const fitsMarketTypeId =
      allowedMarketTypeIds.length > 0
        ? allowedMarketTypeIds.includes(provision.marketTypeId)
        : true;

    return fitsTab && fitsSearch && fitsMarketTypeId;
  });

  if (!filteredData.length) {
    return <EmptyList id="houseDeposits" />;
  }

  const formatDecimal = (value: string) => {
    const numberValue = parseFloat(value);
    if (isNaN(numberValue)) {
      return '0.00';
    }
    return numberValue.toFixed(2);
  };

  const toggleEngagementDetails = (provisionId: string) => {
    setShowEngagementDetails((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(provisionId)) {
        newSet.delete(provisionId);
      } else {
        newSet.add(provisionId);
      }
      return newSet;
    });
  };

  const getWithdrawButtonProps = (provision: HouseProvision) => {
    if (withdrawingMarkets.has(provision.id)) {
      return { text: 'Processing...', disabled: true };
    }

    if (provision.withdrawnAmount === provision.amount) {
      return { text: 'Withdrawn', disabled: true };
    }

    const withdrawableAmount =
      Number(provision.amount) -
      Number(provision.withdrawnAmount) -
      Number(provision.riskExposure || '0');

    if (withdrawableAmount > 0) {
      return {
        text: `Withdraw ${formatDecimal(withdrawableAmount.toString())} SGE`,
        disabled: false,
      };
    }

    if (
      withdrawableAmount <= 0 &&
      provision.withdrawnAmount !== provision.amount
    ) {
      return { text: 'Provision filled', disabled: true };
    }

    return { text: 'Withdraw unavailable', disabled: true };
  };

  return (
    <>
      {filteredData.map((provision) => {
        const statusType = getStatusTypeHouse(provision);

        const returnedAmount =
          Number(provision.amount) +
          Number(provision.profitOrLoss) -
          Number(provision.withdrawnAmount);

        const infoItems: CardFlexItem[] = [
          {
            label: 'Provided:',
            value: `${formatDecimal(provision.amount)} SGE`,
          },
        ];

        if (provision.state === 'Settled') {
          infoItems.push({
            label: 'Returned:',
            value: `${formatDecimal(returnedAmount.toString())} SGE`,
          });
        }

        if (
          provision.profitOrLoss &&
          provision.profitOrLoss !== '0' &&
          provision.state !== 'Active'
        ) {
          infoItems.push({
            label: 'Win/Loss:',
            value: `${formatDecimal(provision.profitOrLoss)} SGE`,
          });
        }

        if (provision.withdrawnAmount !== '0' || provision.state === 'Active') {
          infoItems.push({
            label: 'Withdrawn:',
            value: `${formatDecimal(provision.withdrawnAmount)} SGE`,
          });
        }

        if (provision.totalBets > 0) {
          infoItems.push({
            label: 'Max Risk Exposure:',
            value: `${formatDecimal(provision.riskExposure || '0')} SGE`,
          });
        }

        infoItems.push({
          label: 'Total Bet Amount:',
          value: `${formatDecimal(provision.betValue)} SGE`,
        });

        const { text: buttonText, disabled: buttonDisabled } =
          getWithdrawButtonProps(provision);

        return (
          <ActivityCard
            key={provision.id}
            statusText=""
            statusType={statusType}
            dateText={formatDateToDesiredFormat(provision.createdAt)}
            title={getMarketNameById(provision.marketTypeId) || ''}
            subTitle={provision.fixtureName}
            transactionLink={provision.txHash}
            infoItems={infoItems}
          >
            {provision.state === 'Active' && (
              <Button
                className={WithdrawBtn}
                onClick={() => handleWithdraw(provision)}
                dataId="place_withdraw"
                disabled={buttonDisabled}
              >
                {buttonText}
              </Button>
            )}
            {provision.totalBets > 0 ? (
              <ActionTag
                ariaExpanded={showEngagementDetails.has(provision.id)}
                type="div"
                dataId="show_engagement_details"
                onClick={() => toggleEngagementDetails(provision.id)}
                className={`${FlexContainerBeHouse} mt-2`}
              >
                <img
                  className={HouseIcon}
                  src={
                    showEngagementDetails.has(provision.id)
                      ? MINUS_CIRCLE_ICON
                      : PLUSH_CIRCLE_ICON
                  }
                  alt={
                    showEngagementDetails.has(provision.id)
                      ? 'Collapse'
                      : 'Expand'
                  }
                />
                <span className={`${BeHouseText} my-2`}>
                  {showEngagementDetails.has(provision.id)
                    ? 'Hide Bets'
                    : `View Bets`}{' '}
                  ({provision.totalBets})
                </span>
              </ActionTag>
            ) : (
              <div className={`${FlexContainerBeHouse} mt-2 opacity-25`}>
                <img
                  className={`${HouseIcon} opacity-25 text-gray-500`}
                  src={PLUSH_CIRCLE_ICON}
                  alt="Expand"
                />
                <div className={`${BeHouseText} text-gray-500 my-2`}>
                  No bets to show
                </div>
              </div>
            )}
            {showEngagementDetails.has(provision.id) && (
              <EngagementDetailsCard provision={provision} />
            )}
          </ActivityCard>
        );
      })}
    </>
  );
}

function LoadingComponent() {
  const loaderItems = Array.from({ length: 3 }, (_, index) => (
    <div className={`${LoaderCardContainer} mb-4`} key={index}>
      <div>
        <div className="w-full flex justify-between items-center mb-2">
          <div className={`${LoaderBg} h-6 w-1/4`} />
          <div className={`${LoaderBg} h-5 w-1/3`} />
        </div>
        <div className={`${LoaderBg} h-5 w-1/3 mb-1`} />
        <div className={`${LoaderBg} h-5 w-1/2 mb-2`} />
        <div className={`${LoaderBg} h-5 w-11/12 mb-2`} />
        <div className="w-full flex items-center mb-2">
          <div className={`${LoaderBg} h-5 w-2/5 mr-16`} />
          <div className={`${LoaderBg} h-5 w-1/3`} />
        </div>
        <div className="w-full flex items-center mb-2">
          <div className={`${LoaderBg} h-5 w-4/12 mr-24`} />
          <div className={`${LoaderBg} h-5 w-5/12`} />
        </div>
        <div className={`${LoaderBg} h-5 w-5/12`} />
      </div>
    </div>
  ));

  return <div className="px-0">{loaderItems}</div>;
}

const HouseActivityCards = WithData<HouseProvision[]>(
  HouseActivityCardsWithData,
  LoadingComponent,
  true,
);

export default HouseActivityCards;
