import { DEFAULT_ODD_TYPE } from '#/constants/common/global-constants';
import {
  Title,
  TitleText,
  GroupMatchesCardContainer,
  GroupWrapper,
  TitleWrapper,
} from './GroupedMatches.styled';
import MatchCard from '../MatchCard/MatchCard.component';
import VirtualizerContainer from '#/components/common/Virtualizer/Virtualizer.component';
import MatchShimmer from '../MatchShimmer/MatchShimmer.component';
import { FixtureSummary } from '#/utils/backend';
import { QueryParams } from '#/utils/slices/searchSlice';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '#/utils/store';
import { searchInfiniteQuery } from '#/utils/dataLoaders/useSearchLoader';
import EmptyList from '#/components/common/EmptyStates/EmptyState.component';

type GroupMatchesProps = {
  isSearchResult: boolean;
  isFiltered: boolean;
  oddType?: number;
  queryParams: QueryParams;
  title: string;
};

export default function GroupMatches({
  isSearchResult,
  oddType = parseInt(DEFAULT_ODD_TYPE.key, 10),
  queryParams,
  title,
}: GroupMatchesProps) {
  const dispatch = useAppDispatch();
  const [searchObject, setSearchObject] = useState(
    searchInfiniteQuery(dispatch, queryParams, 50),
  );

  useEffect(() => {
    setSearchObject(searchInfiniteQuery(dispatch, queryParams, 50));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  function getMatchCardProps(item: FixtureSummary) {
    return { fixture: item, oddType };
  }

  function HeaderComponent({ totalItems }: { totalItems: number }) {
    return (<>
        {totalItems === 0 ? (
          <EmptyList id={ isSearchResult ? "search" : "homepage"} />)
          : isSearchResult ? (<div className={TitleWrapper}>
            <span className={Title}>{ 'Search Results:'}</span>
            <span className={TitleText}>{`${totalItems} ${
            totalItems === 1 ? ' match' : ' matches'
          }`}</span>
          </div>): null
        }
      </>
    );
  }

  return (
    <div className={GroupMatchesCardContainer}>
      <div className={GroupWrapper}>
        {VirtualizerContainer<FixtureSummary>({
          title: title,
          ChildComponent: MatchCard,
          getProps: getMatchCardProps,
          query: searchObject,
          LoadingComponent: MatchShimmer,
          HeaderComponent: HeaderComponent,
          isWindowRef: true,
          estimateSize: 300,
          overScan: 5,
          itemsViewConstant: 4,
          virtualizerHeight: '4250px',
        })}
      </div>
    </div>
  );
}
