import {
  FixtureDetail,
  FixtureSummary,
  FixtureTab,
  Odds,
} from '#/utils/backend';
import MarketCard from './MarketCard/MarketCard.component';
import WithData from './Wrapper/WrapWithTransitionStates';
import {
  LoaderBg,
  LoaderBtn,
  LoaderCardContainer,
  LoaderSeparator,
} from './common/LoaderStyle/LoaderStyle.styled';
import { UPARROW_BRAND_ICON } from '#/constants/common/images.constants';
import { getMarketsInCategory } from '#/utils/marketsMap';
import { useState } from 'react';

function MarketsWithData({
  data: fixture,
  fixtureTabSelected,
  addBetSlip,
  addHouseSlip,
}: {
  data: FixtureDetail;
  expanded: boolean;
  setExpandedCard: (item: boolean) => void;
  fixtureTabSelected: FixtureTab;
  addBetSlip: (market: FixtureSummary, odd: Odds) => void;
  addHouseSlip: (market: FixtureSummary) => void;
}) {
  const markets = getMarketsInCategory(
    Object.keys(fixture.markets),
    fixtureTabSelected.id,
  ).flatMap((id) => fixture.markets[id]);

  const [expandedCards, setExpandedCards] = useState<Set<number>>(
    new Set(markets.length > 0 ? [markets[0].id] : []),
  );

  return markets.map((market) => {
    const fixtureSummary: FixtureSummary = {
      id: market.id,
      fixtureId: fixture.id,
      fixtureName: fixture.name,
      fixtureStatus: fixture.status,
      marketStatus: market.status,
      marketTypeName: market.marketTypeName,
      marketTypeId: market.marketTypeId,
      startDate: fixture.startDate,
      sport: fixture.sport,
      acceptableProvisionSize: market.acceptableProvisionSize,
      houseProvision: market.houseProvision,
      league: fixture.league,
      teams: fixture.teams,
      betLine: market.betLine,
      odds: market.odds,
    };

    return (
      <MarketCard
        key={market.id}
        market={fixtureSummary}
        expanded={expandedCards.has(market.id)}
        setExpandedCard={(expanded) => {
          if (expanded) {
            setExpandedCards((prev) => new Set(prev).add(market.id));
          } else {
            setExpandedCards((prev) => {
              const newSet = new Set(prev);
              newSet.delete(market.id);
              return newSet;
            });
          }
        }}
        addBetSlip={addBetSlip}
        addHouseSlip={addHouseSlip}
      />
    );
  });
}

const LoadingComponent = () => {
  return (
    <div className="px-0">
      <div className={`${LoaderCardContainer} !rounded-none`}>
        <div>
          <div className="flex justify-between mb-3">
            <div className={`${LoaderBg} h-4 w-1/3`}></div>
            <span>
              <img className="w-3" src={UPARROW_BRAND_ICON} alt="icon" />
            </span>
          </div>
          <div className="flex space-x-2 mb-2">
            <div className={`${LoaderBg} ${LoaderBtn}`}></div>
            <div className={`${LoaderBg} ${LoaderBtn}`}></div>
            <div className={`${LoaderBg} ${LoaderBtn}`}></div>
          </div>
          <div className="text-white font-xs font-normal">MARKET STATS</div>
          <div className="w-full mt-2 mb-3">
            <div className="flex w-full space-x-2 items-center mb-2">
              <div className={`${LoaderBg} size-4`}></div>
              <div className={`${LoaderBg} h-4 w-1/2`}></div>
              <div className={`${LoaderBg} h-4 w-12`}></div>
            </div>
            <div className="flex w-full space-x-2 items-center  mb-2">
              <div className={`${LoaderBg} size-4`}></div>
              <div className={`${LoaderBg} h-4 w-2/3`}></div>
              <div className={`${LoaderBg} h-4 w-12`}></div>
            </div>
            <div className="flex w-full space-x-2 items-center  mb-2">
              <div className={`${LoaderBg} size-4`}></div>
              <div className={`${LoaderBg} h-4 w-1/3`}></div>
              <div className={`${LoaderBg} h-4 w-12`}></div>
            </div>
          </div>
          <div className={`${LoaderBg} !rounded-md h-10 w-full`}></div>
        </div>
        <div className={LoaderSeparator} />
        <div>
          <div className="flex justify-between mb-3">
            <div className={`${LoaderBg} h-4 w-1/3`}></div>
            <span>
              <img className="w-3" src={UPARROW_BRAND_ICON} alt="icon" />
            </span>
          </div>
          <div className="flex space-x-2 mb-2">
            <div className={`${LoaderBg} ${LoaderBtn}`}></div>
            <div className={`${LoaderBg} ${LoaderBtn}`}></div>
            <div className={`${LoaderBg} ${LoaderBtn}`}></div>
          </div>
          <div className="text-white font-xs font-normal">MARKET STATS</div>
          <div className="w-full mt-2 mb-3">
            <div className="flex w-full space-x-2 items-center mb-2">
              <div className={`${LoaderBg} size-4`}></div>
              <div className={`${LoaderBg} h-4 w-1/2`}></div>
              <div className={`${LoaderBg} h-4 w-12`}></div>
            </div>
            <div className="flex w-full space-x-2 items-center  mb-2">
              <div className={`${LoaderBg} size-4`}></div>
              <div className={`${LoaderBg} h-4 w-2/3`}></div>
              <div className={`${LoaderBg} h-4 w-12`}></div>
            </div>
            <div className="flex w-full space-x-2 items-center  mb-2">
              <div className={`${LoaderBg} size-4`}></div>
              <div className={`${LoaderBg} h-4 w-1/3`}></div>
              <div className={`${LoaderBg} h-4 w-12`}></div>
            </div>
          </div>
          <div className={`${LoaderBg} !rounded-md h-10 w-full`}></div>
        </div>
        <div className={LoaderSeparator} />
        <div>
          <div className="flex justify-between mb-3">
            <div className={`${LoaderBg} h-4 w-1/3`}></div>
            <span>
              <img className="w-3" src={UPARROW_BRAND_ICON} alt="icon" />
            </span>
          </div>
          <div className="flex space-x-2 mb-2">
            <div className={`${LoaderBg} ${LoaderBtn}`}></div>
            <div className={`${LoaderBg} ${LoaderBtn}`}></div>
            <div className={`${LoaderBg} ${LoaderBtn}`}></div>
          </div>
          <div className="text-white font-xs font-normal">MARKET STATS</div>
          <div className="w-full mt-2 mb-3">
            <div className="flex w-full space-x-2 items-center mb-2">
              <div className={`${LoaderBg} size-4`}></div>
              <div className={`${LoaderBg} h-4 w-1/2`}></div>
              <div className={`${LoaderBg} h-4 w-12`}></div>
            </div>
            <div className="flex w-full space-x-2 items-center  mb-2">
              <div className={`${LoaderBg} size-4`}></div>
              <div className={`${LoaderBg} h-4 w-2/3`}></div>
              <div className={`${LoaderBg} h-4 w-12`}></div>
            </div>
            <div className="flex w-full space-x-2 items-center  mb-2">
              <div className={`${LoaderBg} size-4`}></div>
              <div className={`${LoaderBg} h-4 w-1/3`}></div>
              <div className={`${LoaderBg} h-4 w-12`}></div>
            </div>
          </div>
          <div className={`${LoaderBg} !rounded-md h-10 w-full`}></div>
        </div>
      </div>
    </div>
  );
};

const Markets = WithData<FixtureDetail>(
  MarketsWithData,
  LoadingComponent,
  true,
);

export default Markets;
