export const PageContainer = `w-full mt-4 px-4`;
export const Header = `w-full flex items-center`;
export const PageTitle = `text-white font-normal text-base xxxs:text-2xl tracking-wider`;
export const PageSubTitle = `text-white font-normal text-xs xxxs:text-sm tracking-wider mb-3`;
export const ConversionList = `list-none text-sm text-white font-light mb-3`;
export const FieldContainer = `w-full mb-4`;
export const InputLabel = `block text-white font-normal text-sm`;
export const InputField = `w-full bg-primary-bg-3 text-base font-normal text-white placeholder:text-white focus:outline-none pl-4 pr-12 py-2 rounded-md mt-2 !bg-[url('/v2-icons/downarrow-icon-brand.svg')] bg-[length:13px_19px]`;
export const OddsDisplayMsg = `bg-toast-bg-1 rounded-md p-4 flex items-center text-sm xl:text-base font-normal text-white`;
export const InfoIcon = `w-7 mr-3`;
export const DefaultSelectedOdd =
  'w-full bg-primary-bg-3 text-base font-normal text-white pl-4 pr-12 py-2 rounded-md mt-2 bg-[length:13px_19px]';
export const OddsConversionTitle = `text-white font-normal text-base xxxs:text-2xl tracking-wider mb-3`;
export const OddsTitle = `text-base font-normal text-white`;
export const OddsInputField = `w-1/7 bg-transparent text-base font-normal text-white placeholder:text-white ml-2 !bg-[url('/v2-icons/downarrow-icon-brand.svg')] bg-[length:13px_19px] pl-4 pr-12 py-2`;
export const OddsConversionContainer = `flex flex-col mt-8`;
