import { WalletName } from '#/utils/wallets';
import * as Sentry from '@sentry/browser';

/**
 * This file provides a set of custom error classes and error parsing utilities for wallet-related errors.
 * It exists because:
 * 1. It provides a standardized way to handle wallet-specific errors across different wallet types.
 * 2. It allows for easy error classification and handling in the application.
 * 3. It integrates with Sentry for error tracking and reporting.
 */

// Error classes
function WalletErrorClass(code: WalletErrorCode, defaultMessage: string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function <T extends { new (...args: any[]): WalletError }>(
    constructor: T,
  ) {
    return class extends constructor {
      code = code;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      constructor(...args: any[]) {
        super(args.length > 0 ? args[0] : defaultMessage);
        this.name = constructor.name;
      }
    };
  };
}

export enum WalletErrorCode {
  Unknown = 'UNKNOWN',
  Unauthorized = 'UNAUTHORIZED',
  InsufficientGas = 'INSUFFICIENT_GAS',
  RequestRejected = 'REQUEST_REJECTED',
  MarketNotOperating = 'MARKET_NOT_OPERATING',
}

export class WalletError extends Error {
  code: WalletErrorCode = WalletErrorCode.Unknown;
}

export const UnauthorizedError = WalletErrorClass(
  WalletErrorCode.Unauthorized,
  'Unauthorized: Contract execution failed',
)(class extends WalletError {});

export const InsufficientGasError = WalletErrorClass(
  WalletErrorCode.InsufficientGas,
  'Insufficient gas for contract execution',
)(class extends WalletError {});

export const RequestRejectedError = WalletErrorClass(
  WalletErrorCode.RequestRejected,
  'User cancelled the wallet interaction',
)(class extends WalletError {});

export const MarketNotOperatingError = WalletErrorClass(
  WalletErrorCode.MarketNotOperating,
  'Market is no longer operational',
)(class extends WalletError {});

/**
 * Patterns used to identify specific types of errors based on their error messages.
 */
const ERROR_PATTERNS = {
  requestRejected: ['request rejected', 'user rejected'],
  unauthorized: 'unauthorized: execute wasm contract failed',
  insufficientGas: ['with gas wanted:', 'and gas used:'],
  marketNotOperating: [
    'market is no longer operational',
    'market is not operational',
  ],
};

function extractNumber(message: string, pattern: RegExp): number | null {
  const match = message.match(pattern);
  return match ? parseInt(match[1], 10) : null;
}

/**
 * Base class for wallet error parsers. Implements common parsing logic and
 * integrates with Sentry for error reporting.
 */
export abstract class BaseErrorParser {
  protected getErrorMessage(error: Error): string {
    return error.message.toLowerCase();
  }

  parse(error: Error): WalletError | null {
    const errorMessage = this.getErrorMessage(error);
    let parsedError: WalletError | null = null;

    if (
      ERROR_PATTERNS.requestRejected.some((pattern) =>
        errorMessage.includes(pattern),
      )
    ) {
      parsedError = new RequestRejectedError();
    } else if (
      ERROR_PATTERNS.marketNotOperating.some((pattern) =>
        errorMessage.includes(pattern),
      )
    ) {
      parsedError = new MarketNotOperatingError();
    } else if (errorMessage.includes(ERROR_PATTERNS.unauthorized)) {
      if (
        ERROR_PATTERNS.insufficientGas.every((pattern) =>
          errorMessage.includes(pattern),
        )
      ) {
        const gasWanted = extractNumber(errorMessage, /gas wanted: '(\d+)'/);
        const gasUsed = extractNumber(errorMessage, /gas used: '(\d+)'/);

        if (gasWanted && gasUsed && gasUsed < gasWanted) {
          parsedError = new InsufficientGasError();
        }
      }
      if (!parsedError) {
        parsedError = new UnauthorizedError();
      }
    } else {
      parsedError = this.parseSpecific(error);
    }

    if (parsedError) {
      Sentry.captureException(parsedError, {
        extra: {
          originalError: error,
          walletType: this.constructor.name,
        },
      });
    }

    return parsedError;
  }

  protected abstract parseSpecific(error: Error): WalletError | null;
}

export class DefaultErrorParser extends BaseErrorParser {
  protected parseSpecific(error: Error): WalletError | null {
    return null;
  }
}

export class KeplrErrorParser extends BaseErrorParser {
  protected parseSpecific(error: Error): WalletError | null {
    // Add Keplr-specific error parsing here
    return null;
  }
}

export class LeapErrorParser extends BaseErrorParser {
  protected parseSpecific(error: Error): WalletError | null {
    // Add Leap-specific error parsing here
    return null;
  }
}

export enum WalletType {
  Default = 'default',
  Keplr = 'keplr',
  Leap = 'leap',
}

export const walletErrorParsers: Record<WalletType, BaseErrorParser> = {
  [WalletType.Default]: new DefaultErrorParser(),
  [WalletType.Keplr]: new KeplrErrorParser(),
  [WalletType.Leap]: new LeapErrorParser(),
};

export const getWalletType = (walletName: string): WalletType => {
  switch (walletName) {
    case WalletName.KeplrMobile:
      return WalletType.Keplr;
    case WalletName.KeplrExtension:
      return WalletType.Keplr;
    case WalletName.LeapMobile:
      return WalletType.Leap;
    case WalletName.LeapExtension:
      return WalletType.Leap;
    default:
      return WalletType.Default;
  }
};

export function parseWalletError(
  error: Error,
  walletType: WalletType = WalletType.Default,
): WalletError | null {
  const parser =
    walletErrorParsers[walletType] || walletErrorParsers[WalletType.Default];
  const parsedError = parser.parse(error);

  if (!parsedError) {
    console.warn(`Unhandled wallet error for ${walletType}:`, error);
  }

  return parsedError;
}
