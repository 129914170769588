import React from 'react';
import CollapseCard from '#/components/common/CollapseCard';
import ActionTag from '#/components/common/ActionTags.component';
import {
  CheckInput,
  FilterCardContainer,
  FilterList,
  FilterListItemContainer,
  FilterListItemText,
  HeadDivider,
  HeaderWrapper,
  RemoveText,
  Title,
  TopLeague,
} from './FilterCards.styled';
import { CardProps } from './FilterCards.types';
import { SelectedLeagueFilter } from '../Filters/Filters.types';
import { LoaderBg } from '../common/LoaderStyle/LoaderStyle.styled';

export const ACCEPT_TEXT = 'Accept';

const FilterCards: React.FC<CardProps> = ({
  title,
  clearFilters,
  setLeagueFilters,
  leagueFilters,
  leagues,
  isLoading,
  isContinentFilter,
}) => {
  const isActive = (item: SelectedLeagueFilter) =>
    leagueFilters.includes(item.Id);

  const handleSelectFilter = (item: SelectedLeagueFilter) => {
    let newLeagues: number[];
    if (isActive(item)) {
      newLeagues = leagueFilters.filter((id: number) => id !== item.Id);
    } else {
      newLeagues = [...leagueFilters, item.Id];
    }
    setLeagueFilters(newLeagues);
  };

  return (
    <div className="overflow-y-auto">
      {isContinentFilter ? null : (
        <div className={HeaderWrapper}>
          <span className={Title}>Leagues</span>
          <span
            className={RemoveText}
            onClick={clearFilters}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                clearFilters();
              }
            }}
          >
            Remove All
          </span>
        </div>
      )}
      <hr className={HeadDivider} />
      <CollapseCard
        title={
          isContinentFilter ? null : (
            <span className={TopLeague}>TOP LEAGUES</span>
          )
        }
      >
        <div className={FilterCardContainer}>
          <ul id="CardFilterList" className={FilterList}>
            {isLoading ? (
              <div>
                <div className='flex mb-4'>
                  <div className={`${LoaderBg} size-6 mr-3`}></div>
                  <div className={`${LoaderBg} h-6 w-2/3`}></div>
                </div>
                <div className='flex mb-4'>
                  <div className={`${LoaderBg} size-6 mr-3`}></div>
                  <div className={`${LoaderBg} h-6 w-2/3`}></div>
                </div>
                <div className='flex mb-4'>
                  <div className={`${LoaderBg} size-6 mr-3`}></div>
                  <div className={`${LoaderBg} h-6 w-2/3`}></div>
                </div>
                <div className='flex mb-4'>
                  <div className={`${LoaderBg} size-6 mr-3`}></div>
                  <div className={`${LoaderBg} h-6 w-2/3`}></div>
                </div>
              </div>
            ) : (
              leagues?.map((item) => (
                <li className={FilterListItemContainer} key={item.Id}>
                  <ActionTag
                    type="div"
                    className={FilterListItemText({ isActive: isActive(item) })}
                    onClick={() => handleSelectFilter(item)}
                    dataId={`${title}_filter_click`}
                  >
                    <input
                      className={CheckInput}
                      aria-labelledby={ACCEPT_TEXT}
                      type="checkbox"
                      id={String(item.Id)}
                      checked={isActive(item)}
                    />
                    <div>{item?.Name}</div>
                  </ActionTag>
                </li>
              ))
            )}
          </ul>
        </div>
      </CollapseCard>
      <div></div>
    </div>
  );
};

export default FilterCards;
