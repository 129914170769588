import { useQuery } from '@tanstack/react-query';
import useAccessToken from './useAccessToken';
import { MINUTE } from '../time';
import { loadBackendUserData } from '../backend';
import useAuth0UserData from './useAuth0UserData';

export const queryKey = (id : string | undefined) => {
  return ['backendUserData', id]
}

const useBackendUserData = () => {
  const { data: auth0User } = useAuth0UserData();
  const { data: accessToken } = useAccessToken();

  return useQuery({
    queryKey: queryKey(auth0User?.sub),
    queryFn: async () => {
      if (accessToken) {
        return loadBackendUserData(accessToken);
      }
    },
    retry: 3,
    refetchInterval: MINUTE,
    refetchOnWindowFocus: true,
    refetchOnMount: false,
    enabled: !!auth0User?.sub && !!accessToken,
  });
};

export default useBackendUserData;
