import { SearchListContainer2 } from './SearchResults.styled';
import GroupMatches from '#/components/MatchCard/GroupedMatches/GroupedMatches.component';
import { useEffect, useState } from 'react';
import { QueryParams } from '#/utils/slices/searchSlice';

const SearchResults = ({ searchQuery }: { searchQuery: QueryParams }) => {
  const [queryParam, setQueryParam] = useState<QueryParams>(searchQuery);

  useEffect(() => {
    setQueryParam(searchQuery);
  }, [searchQuery]);

  if (!searchQuery) return null;

  return (
    <div className={SearchListContainer2}>
      <GroupMatches
        isSearchResult={!!searchQuery.search.length}
        isFiltered={false}
        queryParams={queryParam}
        title={`search-result`}
      />
    </div>
  );
};

export default SearchResults;
